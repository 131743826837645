// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contactus-js": () => import("./../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-covid-js": () => import("./../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-scheduleademo-js": () => import("./../src/pages/scheduleademo.js" /* webpackChunkName: "component---src-pages-scheduleademo-js" */),
  "component---src-pages-schoolsintrested-js": () => import("./../src/pages/schoolsintrested.js" /* webpackChunkName: "component---src-pages-schoolsintrested-js" */),
  "component---src-pages-tagetstarted-js": () => import("./../src/pages/tagetstarted.js" /* webpackChunkName: "component---src-pages-tagetstarted-js" */)
}

