/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("typeface-roboto")
require("typeface-roboto-slab")


/* import 'typeface-roboto';

import 'typeface-roboto-slab';  */

//export function onServiceWorkerUpdateReady(){window.location.reload()};